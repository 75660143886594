import Map from "./Map";
import CitySearch from "./CitySearch";
import { XCircleIcon, ArrowPathIcon } from "@heroicons/react/20/solid";
import { useState, useEffect } from "react";
import PricingResults from "./PricingResults";
import { cellToChildren } from "h3-js";
import { useAuth0 } from "@auth0/auth0-react";
import { useSearchParams } from "react-router-dom";

const equipmentTypeOptions = [
  { name: "DRY", displayName: "Dry" },
  { name: "REEFER", displayName: "Reefer" },
];

Date.prototype.toDateInputValue = function () {
  const local = new Date(this);
  local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
};

const fetchLaneData = async function (token, searchParams) {
  let laneDataPromise = Promise.resolve(null);

  if (searchParams.get("id")) {
    laneDataPromise = fetch(
      `https://api.leaflogistics.com/analytics/crud/lane?id=eq.${searchParams.get(
        "id"
      )}&select=*,origin:location!lane_origin_location_id_fkey(name, geometry),destination:location!lane_destination_location_id_fkey(name, geometry)`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_POSTGREST_JWT}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        data[0].origin.geometry.coordinates[0].map((item) => item.reverse());
        data[0].destination.geometry.coordinates[0].map((item) =>
          item.reverse()
        );
        data[0].geometry.coordinates.map((item) => item.reverse());

        return data[0];
      });
  }

  const localUrl = new URL(window.location.href);
  const apiUrl = new URL("https://api.leaflogistics.com");
  apiUrl.pathname = `pricing/${localUrl.pathname}`.replace('//', '/');
  apiUrl.search = new URLSearchParams(searchParams);
  console.log(apiUrl.toString());

  const pricingDataPromise = fetch(apiUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      data.matchedContracts.sort((a, b) => a.linehaul_rpm - b.linehaul_rpm);
      return data;
    })
    .catch((error) => {
      alert("Unable to get pricing data: " + error.message);
    });

  return Promise.all([laneDataPromise, pricingDataPromise]);
};

export default function Container(props) {
  // search params
  const [searchParams, setSearchParams] = useSearchParams();
  // console.log(new URLSearchParams(searchParams).toString());

  const [laneData, setLaneData] = useState(null);
  const [pricingData, setPricingData] = useState(null);

  const [inProgress, setInProgress] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const onGetPrice = () => {
    setInProgress(true);
    getAccessTokenSilently()
      .then((token) => fetchLaneData(token, searchParams))
      .then(([data, pricingData]) => {
        // console.log(data);
        // console.log(pricingData);
        setLaneData(data);
        setPricingData(pricingData);
        setInProgress(false);
      });
  };

  useEffect(() => {
      if (!pricingData && searchParams.get("id")) {
        onGetPrice();
      }
  }, []);

  return (
    <div className="w-screen max-h-screen h-screen">
      <div className="grid grid-cols-12">
        <div className="col-span-6 h-screen relative">
          <Map laneData={laneData} />
        </div>
        <div className="col-span-6">
          <PricingResults
            // originArea={originArea}
            // destinationArea={destinationArea}
            result={pricingData}
            inProgress={inProgress}
            startDate={
              searchParams.get("start_date") || new Date().toDateInputValue()
            }
            setStartDate={(value) => {
              setSearchParams((params) => {
                searchParams.set("start_date", value);
                return searchParams;
              });
            }}
            endDate={
              searchParams.get("end_date") || new Date().toDateInputValue()
            }
            setEndDate={(value) => {
              setSearchParams((params) => {
                searchParams.set("end_date", value);
                return searchParams;
              });
            }}
            indexDate={
              searchParams.get("index_reference_date") ||
              new Date().toDateInputValue()
            }
            setIndexDate={(value) => {
              setSearchParams((params) => {
                searchParams.set("index_reference_date", value);
                return searchParams;
              });
            }}
            equipmentType={
              equipmentTypeOptions.find(
                (item) => item.name === searchParams.get("equipment_type")
              ) || equipmentTypeOptions[0]
            }
            setEquipmentType={(value) =>
              setSearchParams((params) => {
                searchParams.set("equipment_type", value.name);
                return searchParams;
              })
            }
            equipmentTypeOptions={equipmentTypeOptions}
            laneData={laneData}
            onGetPrice={onGetPrice}
          />
        </div>
      </div>
    </div>
  );
}
