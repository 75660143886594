import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

export default function LegacyRedirect(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    navigate({
        pathname: "/lane",
        search: searchParams.toString(),
        replace: true,
      });
  }, [navigate, searchParams])
  
  return <div>Redirecting...</div>;
}
