import { MapContainer, TileLayer, useMap, Polygon, Polyline } from 'react-leaflet';
// needs to be imported for area selection to work
import SelectArea from 'leaflet-area-select';
import { useState } from 'react';

import 'leaflet/dist/leaflet.css';
import * as turf from "@turf/turf";
import { polygonToCells, cellsToMultiPolygon } from 'h3-js';

const H3_SELECTION_RESOLUTION = 3;

function AreaSelection(props) {
    const map = useMap();
    map.selectArea.enable();

    map.on('areaselected', (e) => {
        const poly = turf.bboxPolygon(e.bounds.toBBoxString().split(',').map((x) => parseFloat(x)));
        const cells = polygonToCells(poly.geometry.coordinates, H3_SELECTION_RESOLUTION, true)
        if (props.destinationChecked) {
            props.onOriginAreaSelect([...props.originArea]);
            props.onDestinationAreaSelect([...props.destinationArea, ...cells]);
        }
        else {
            props.onOriginAreaSelect([...props.originArea, ...cells]);
            props.onDestinationAreaSelect([...props.destinationArea]);
        }
    });

    return null
}

function SelectionPolygon(props) {
    return <Polygon
        positions={props.positions}
        pathOptions={props.pathOptions}
        clickable={true}
    />
}


function Map(props) {
    const [center, setCenter] = useState([37.11, -101.29]);
    const [zoom, setZoom] = useState(4);

    return <MapContainer
        center={center}
        zoom={zoom}
        zoomControl={false}
        scrollWheelZoom={true}
        style={{ height: '100%', width: '100%' }} >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <AreaSelection
            originArea={props.originArea}
            destinationArea={props.destinationArea}
            destinationChecked={props.destinationChecked}
            onOriginAreaSelect={props.onOriginAreaSelect}
            onDestinationAreaSelect={props.onDestinationAreaSelect}
        />
        {
            props.originArea && props.originArea.length > 0 ?
                <SelectionPolygon
                    positions={cellsToMultiPolygon(props.originArea)}
                    pathOptions={{ color: "forestgreen", weight: 1, opacity: 0.5, fillOpacity: 0.1 }}
                />
                : <></>
        }
        {
            props.destinationArea && props.destinationArea.length > 0 ?
                <SelectionPolygon
                    positions={cellsToMultiPolygon(props.destinationArea)}
                    pathOptions={{ color: "#B73239", weight: 1, opacity: 0.5, fillOpacity: 0.1 }}
                />
                : <></>
        }
        {
            props.laneData ? <>
                <Polyline positions={props.laneData.geometry.coordinates}  pathOptions={{ color: "forestgreen", weight: 5, opacity: 0.5, fillOpacity: 0.5 }}/>
                <Polygon positions={props.laneData.origin.geometry.coordinates} pathOptions={{ color: "forestgreen", weight: 1, opacity: 1, fillOpacity: 0.5 }}/>
                <Polygon positions={props.laneData.destination.geometry.coordinates}  pathOptions={{ color: "#B73239", weight: 1, opacity: 1, fillOpacity: 0.5 }}/>
            </> : <></>
        }
    </MapContainer>
}

export default Map;
