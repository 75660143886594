import { PlayIcon, ClipboardDocumentIcon } from "@heroicons/react/20/solid";
import { useRef, useState, useEffect } from "react";
import React from "react";

import { createToast } from "react-simple-toasts";
import DropdownMenu from "./DropdownMenu";
import { useSearchParams, useNavigate } from "react-router-dom";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const tabs = [
  { id: "id", name: "Adapt Lane ID", href: "#", default: true },
  { id: "zip", name: "ZIP codes", href: "#", default: false },
  // { id: "coords", name: "Coordinates", href: "#", default: false },
];

const deleteKeys = (searchParams, keys) => {
  keys.forEach((key) => {
    searchParams.delete(key);
  });
};

const customToast = createToast({
  duration: 1000,
  theme: "dark",
  className: "custom-toast",
  clickClosable: true,
  position: "bottom-right",
  maxVisibleToasts: 1,
  render: (message) => <b className="my-toast">{message}</b>,
});

const DatePicker = (props) => {
  const dateInputRef = useRef(null);

  const handleChange = (e) => {
    props.setDate(e.target.value);
  };

  return (
    <div>
      <span className="text-sm font-light">{props.label}</span>:{" "}
      <input
        type="date"
        onChange={handleChange}
        ref={dateInputRef}
        value={props.date}
      />
    </div>
  );
};

function Spinner() {
  return (
    <div role="status">
      <svg
        aria-hidden="true"
        className="inline w-4 h-4 mr-1 mb-0.5 text-sky-200 animate-spin dark:text-lightgray-900 fill-green-800"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    </div>
  );
}

export default function PricingResults(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState({id: null});
  const navigate = useNavigate();
  const [laneID, setLaneID] = useState(
    searchParams.get("id") || "a9762c48-5453-4613-9ee8-36595672a97a"
  );
  const [oZip, setOZip] = useState(searchParams.get("o_zip") || "80112");
  const [dZip, setDZip] = useState(searchParams.get("d_zip") || "67470");
  const [oLat, setOLat] = useState(searchParams.get("o_lat") || 39.5696);
  const [oLon, setOLon] = useState(searchParams.get("o_lon") || -104.8571);
  const [dLat, setDLat] = useState(searchParams.get("d_lat") || 38.8403);
  const [dLon, setDLon] = useState(searchParams.get("d_lon") || -97.6114);

  useEffect(() => {
    console.log("called")
    if (searchParams.get("id") && selectedTab !== tabs[0]) {
      setSelectedTab(tabs[0]);
    } else if (searchParams.get("o_zip") && selectedTab !== tabs[1]) {
      setSelectedTab(tabs[1]);
    } else if (searchParams.get("o_lat") && selectedTab !== tabs[2]) {
      setSelectedTab(tabs[2]);
    }
  }, []);

  useEffect(() => {
    if (selectedTab.id === "id") {
      deleteKeys(searchParams, [
        "o_zip",
        "d_zip",
        "o_lat",
        "o_lon",
        "d_lat",
        "d_lon",
      ]);
      searchParams.set("id", laneID);
      navigate(`/lane?${searchParams.toString()}`);
    } else if (selectedTab.id === "zip") {
      deleteKeys(searchParams, ["id", "o_lat", "o_lon", "d_lat", "d_lon"]);
      searchParams.set("o_zip", oZip);
      searchParams.set("d_zip", dZip);
      navigate(`/zip?${searchParams.toString()}`);
    } else if (selectedTab.id === "coords") {
      deleteKeys(searchParams, ["id", "o_zip", "d_zip"]);
      searchParams.set("o_lat", oLat);
      searchParams.set("o_lon", oLon);
      searchParams.set("d_lat", dLat);
      searchParams.set("d_lon", dLon);
      navigate(`/coordinates?${searchParams.toString()}`);
    }
  }, [selectedTab, laneID, oZip, dZip, oLat, oLon, dLat, dLon]);

  return (
    <div className="grid grid-cols-1">
      <div className="col-span-7 h-screen text-left overflow-y-scroll">
        {/* Input Tabs */}
        <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400 mt-2 ml-2">
          {tabs.map((tab, index) => {
            return (
              <li key={`tab-${index}`} className="mr-2">
                <button
                  className={`inline-block p-2 text-black bg-gray-100 hover:text-gray-600 hover:bg-gray-50 rounded-t-lg ${
                    (selectedTab?.id === tab.id)
                      ? "bg-white shadow"
                      : "bg-gray-200"
                  }`}
                  onClick={() => {
                    setSelectedTab(tab);
                  }}
                >
                  {tab.name}
                </button>
              </li>
            );
          })}
        </ul>
        {selectedTab?.id === "id" && (
          <div className="m-2 w-1/3">
            <label
              htmlFor="lane_id"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Lane ID
            </label>
            <input
              type="text"
              id="lane_id"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
              placeholder=""
              defaultValue={laneID}
              onChange={(e) => {
                setLaneID(e.target.value);
              }}
            ></input>
          </div>
        )}
        {selectedTab?.id === "zip" && (
          <div className="m-2 flex flex-row">
            <div className="mr-2">
              <label
                htmlFor="origin_zip"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Origin ZIP
              </label>
              <input
                type="text"
                id="origin_zip"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                placeholder=""
                defaultValue={oZip}
                onChange={(e) => {
                  setOZip(e.target.value);
                }}
              ></input>
            </div>
            <div>
              <label
                htmlFor="destination_zip"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Destination ZIP
              </label>
              <input
                type="text"
                id="destination_zip"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                placeholder=""
                defaultValue={dZip}
                onChange={(e) => {
                  setDZip(e.target.value);
                }}
              ></input>
            </div>
          </div>
        )}
        {selectedTab?.id === "coords" && (
          <div className="m-2 flex flex-row">
            <div className="mr-2">
              <label
                htmlFor="lane_id"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Origin Latitude
              </label>
              <input
                type="text"
                id="lane_id"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                placeholder=""
                defaultValue={oLat}
                onChange={(e) => {
                  setOLat(e.target.value);
                }}
              ></input>
            </div>
            <div className="mr-2">
              <label
                htmlFor="lane_id"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Origin Longitude
              </label>
              <input
                type="text"
                id="lane_id"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                placeholder=""
                defaultValue={oLon}
                onChange={(e) => {
                  setOLon(e.target.value);
                }}
              ></input>
            </div>
            <div className="mr-2">
              <label
                htmlFor="lane_id"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Destination Latitude
              </label>
              <input
                type="text"
                id="lane_id"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                placeholder=""
                defaultValue={dLat}
                onChange={(e) => {
                  setDLat(e.target.value);
                }}
              ></input>
            </div>
            <div className="mr-2">
              <label
                htmlFor="lane_id"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Destination Longitude
              </label>
              <input
                type="text"
                id="lane_id"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                placeholder=""
                defaultValue={dLon}
                onChange={(e) => {
                  setDLon(e.target.value);
                }}
              ></input>
            </div>
          </div>
        )}

        <div className="my-2 ml-1">
          <DropdownMenu
            options={props.equipmentTypeOptions}
            onClick={props.setEquipmentType}
            name={
              props.equipmentType
                ? props.equipmentType.displayName
                : "Equipment"
            }
          />
          <div className="relative inline-flex items-center rounded-md border border-gray-300 bg-white m-1 p-1 text-sm font-medium text-gray-700 hover:bg-gray-100 p-1.5">
            <DatePicker
              label={"Valid on"}
              date={props.startDate}
              setDate={props.setStartDate}
            />
          </div>
          <button className="relative inline-flex items-center rounded-md border border-gray-300 bg-white m-1 p-1 text-sm font-medium text-gray-700 hover:bg-gray-100 p-1.5">
            <DatePicker
              label={"Valid at least until"}
              date={props.endDate}
              setDate={props.setEndDate}
            />
          </button>
          <button className="relative inline-flex items-center rounded-md border border-gray-300 bg-white m-1 p-1 text-sm font-medium text-gray-700 hover:bg-gray-100 p-1.5">
            <DatePicker
              label={"DAT reference date"}
              date={props.indexDate}
              setDate={props.setIndexDate}
            />
          </button>
          <br />
          <button className="absolute top-2 right-2 items-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-100 p-1.5">
            <a href="mailto:horatiu.bota@leaflogistics.com?subject=Pricing%20App%20Feedback&body=The%20app%20is%20great%20but">
              Send Feedback
            </a>
          </button>
          <button
            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white mt-8 m-1 p-1 text-sm font-medium text-gray-700 hover:bg-gray-100 p-1.5"
            onClick={props.onGetPrice}
          >
            {props.inProgress ? (
              <Spinner className="h-4 w-4" />
            ) : (
              <PlayIcon className="h-4 w-4 fill-green-900 mr-1" />
            )}
            Get price
          </button>
        </div>
        <hr />
        <div className="m-2 mt-4">
          {props.laneData ? (
            <div className="font-light text-xl mb-4">
              <span>
                {props.laneData.origin.name} to{" "}
                {props.laneData.destination.name}
              </span>
              <br />
              <span className="font-light text-sm">
                {props.laneData.lane_type} lane
              </span>
              {" - "}
              <span className="font-light text-sm">
                {props.laneData.leaf_miles} miles
              </span>
              {" - "}
              <span className="font-light text-sm">
                Company ID: {props.laneData.company_id}
              </span>
              <br />
              {props.laneData.leaf_miles < 150 ? (
                <span className="font-medium text-sm text-red-600">
                  Based on lane distance, this is a short haul. DAT RPM may be
                  unreliable for pricing.
                </span>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
          {props.result ? (
            <span className="text-xl font-light mb-1">
              DAT Linehaul Rates:{" "}
            </span>
          ) : (
            <></>
          )}
          {props.result && props.result.historicalRpm.length > 0 ? (
            <div className="font-light text-sm">
              <span className="font-ligt">Rates for origin KMA </span>
              <span className="ml-1 mr-1 font-black">
                {props.result.oMarket.length
                  ? props.result.oMarket.toString()
                  : props.result.oMarket}
              </span>
              {props.result.oZip3 ? (
                <>
                  <span className="ml-1 font-light">
                    (closest zip3 to origin:{" "}
                  </span>{" "}
                  <span className="font-black">{props.result.oZip3}</span>)
                </>
              ) : (
                <></>
              )}
              <span className="ml-1 font-light">to destination KMA </span>
              <span className="ml-1 mr-1 font-black">
                {props.result.dMarket.length
                  ? props.result.dMarket.toString()
                  : props.result.dMarket}
              </span>
              {props.result.dZip3 ? (
                <>
                  <span className="ml-1 font-light">
                    (closest zip3 to destination:{" "}
                  </span>{" "}
                  <span className="font-black">{props.result.dZip3}</span>)
                </>
              ) : (
                <></>
              )}
              {props.result.latestDatDate && props.result.latestDatRpm ? (
                <div className="text-sm mt-1">
                  <span className="font-light">
                    Latest observed DAT linehaul rate:
                  </span>{" "}
                  <span className="font-black">
                    ${props.result.latestDatRpm.toFixed(2)}
                  </span>{" "}
                  on{" "}
                  <span className="font-black">
                    {props.result.latestDatDate}
                  </span>
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
          {props.result ? (
            <Line
              className="min-w-full mt-2"
              data={{
                labels: props.result.historicalRpm
                  .map((item) => item.rateDate)
                  .reverse(),
                datasets: [
                  {
                    label: "Average RPM",
                    data: props.result.historicalRpm
                      .map((item) => item.avgRpm)
                      .reverse(),
                    borderColor: "rgb(255, 99, 132)",
                    backgroundColor: "rgba(255, 99, 132, 0.5)",
                  },
                  {
                    label: "Minus 1 std",
                    data: props.result.historicalRpm
                      .map(
                        (item) =>
                          item.avgRpm - (item.stddevRpm || 0)
                      )
                      .reverse(),
                    borderColor: "rgba(211, 211, 211, 0.5)",
                    backgroundColor: "rgba(	211, 211, 211, 0.5)",
                    pointRadius: 0,
                  },
                  {
                    label: "Plus 1 std",
                    data: props.result.historicalRpm
                      .map(
                        (item) =>
                          item.avgRpm + (item.stddevRpm || 0)
                      )
                      .reverse(),
                    backgroundColor: "rgba(	211, 211, 211, 0.5)",
                    borderColor: "rgba(211, 211, 211, 0.5)",
                    fill: "1",
                    pointRadius: 0,
                  },
                ],
              }}
              options={{
                scales: {
                  y: {
                    beginAtZero: true,
                    title: {
                      display: true,
                      text: "RPM",
                    },
                    ticks: {
                      // Include a dollar sign in the ticks
                      callback: function (value, index, ticks) {
                        return "$" + value.toFixed(2);
                      },
                    },
                  },
                  x: {
                    title: {
                      display: true,
                      text: "DAT rate date",
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: true,
                    position: "bottom",
                    // labels: {
                    //     filter: function (legendItem, data) {
                    //         let label = data.datasets[legendItem.datasetIndex].label || '';
                    //         if (typeof (label) !== 'undefined' && legendItem.datasetIndex >= 1) {
                    //             return false;
                    //         }
                    //         return label;
                    //     }
                    // }
                  },
                },
              }}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="mt-10"></div>
        {props.result ? (
          <div className="flex flex-col ml-4">
            {props.laneData ? (
              <div>
                <span className="text-xl font-light">
                  Best price: ${props.result.linehaulRpm}
                </span>
                <span className="text-sm font-extralight ml-10">
                  Total trip price: ~$
                  {(
                    props.laneData.leaf_miles * props.result.linehaulRpm
                  ).toFixed(1)}
                </span>
              </div>
            ) : (
              <span className="text-xl font-light">
                Best price: {props.result.linehaulRpm}{" "}
              </span>
            )}
            <span className="text-sm font-light">
              (lowest matched contract + 6% Leaf margin, linehaul only)
            </span>{" "}
            <br />
            <span className="text-xl font-light">Matched contracts:</span>
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-6">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div className="overflow-hidden">
                  <table className="min-w-full text-right text-xs font-light">
                    <thead className="border-b font-medium dark:border-neutral-500">
                      <tr>
                        <th scope="col" className="px-2 py-4">
                          ID
                        </th>
                        <th scope="col" className="px-2 py-4">
                          Type
                        </th>
                        <th scope="col" className="px-2 py-4">
                          Carrier
                        </th>
                        <th scope="col" className="px-2 py-4">
                          Linehaul RPM
                        </th>
                        <th scope="col" className="px-2 py-4">
                          Valid From
                        </th>
                        <th scope="col" className="px-2 py-4">
                          Valid To
                        </th>
                        <th scope="col" className="px-2 py-4">
                          Notes
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.result?.matchedContracts ? (
                        props.result.matchedContracts.map((contract, i) => (
                          <tr
                            key={i}
                            className="border-b dark:border-neutral-500"
                          >
                            <td>
                              <div className="px-2 py-4">
                                <ClipboardDocumentIcon
                                  className="w-6 h-6 relative inline-flex items-center rounded-md border border-gray-300 bg-white m-1 p-1 text-sm font-medium text-gray-500 hover:bg-gray-100"
                                  onClick={() => {
                                    navigator.clipboard.writeText(contract.id);
                                    customToast(
                                      "Contract ID copied to clipboard."
                                    );
                                  }}
                                />
                              </div>
                            </td>
                            <td>
                              <span className="text-clip whitespace-nowrap px-2 py-4">
                                {contract.contract_type}
                              </span>
                            </td>
                            <td>
                              <span className="text-clip whitespace-nowrap px-2 py-4">
                                {contract.carrier_name}
                              </span>
                            </td>
                            <td>
                              <span className="text-clip whitespace-nowrap px-2 py-4 text-right">
                                ${contract.linehaul_rpm}
                              </span>
                            </td>
                            <td>
                              <span className="text-clip whitespace-nowrap px-2 py-4">
                                {contract.start_date}
                              </span>
                            </td>
                            <td>
                              <span className="text-clip whitespace-nowrap px-2 py-4">
                                {contract.end_date}
                              </span>
                            </td>
                            <td>
                              <div className="truncate whitespace-nowrap px-2 py-4">
                                {contract.notes}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
