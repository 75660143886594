import './App.css';
import Container from "./components/Container";
import { withAuthenticationRequired } from "@auth0/auth0-react";

function App() {
  return (
    <div className="App max-h-screen h-screen overflow-hidden">
      <Container />
    </div>
  );
}

export default withAuthenticationRequired(App);
