import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import LegacyRedirect from "./components/LegacyRedirect"

const router = createBrowserRouter([
  {
    path: "/lane",
    element: <App />,
  },
  {
    path: "/coordinates",
    element: <App />,
  },
  {
    path: "/zip",
    element: <App />,
  }
  ,
  {
    path: "/",
    element: <LegacyRedirect />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="auth.leaflogistics.com"
      clientId="t7W4MSl4VYShWPuKO6VEfsswvMw5KpN8"
      authorizationParams={{
        audience: "api.leaflogistics.com",
        redirect_uri: window.location.origin,
      }}
    >
      <RouterProvider router={router} />
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
